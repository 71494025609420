<template>
	<LeftNavBar class="f1" :items="$route.meta.leftNav ? navItems : []" @opened="navOpened" :title="selectedAppInitial"
		:selectedValue="$store.state.appData.filter.value">
	</LeftNavBar>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";

export default {
	components: { LeftNavBar },
	name: "menu-left",
	data() {
		return {

		};
	},
	computed: {
		selectedAppInitial() {
			return (
				(this.$store.state.theApp &&
					this.$store.state.theApp.media &&
					this.$store.state.theApp.media.initial) ||
				"CC"
			);
		},

		instance() {
			return this.$store.state.instance
		},

		navItems() {
			const items = [
				{
					label: "All Templates",
					value: "all",
					iconUrl: require("@/assets/sideIcons/all.svg"),
				},
				{
					label: "Purchase Templates",
					value: "purchase",
					iconUrl: require("@/assets/sideIcons/purchase.svg"),
				},
				{
					label: "Lease Templates",
					value: "lease",
					iconUrl: require("@/assets/sideIcons/lease.svg"),
				},
				{
					label: "Amendment/Document",
					value: "amendPurchase",
					iconUrl: require("@/assets/sideIcons/amendPurchase.svg"),
				},
				{
					label: "Amendment Lease",
					value: "amendLease",
					iconUrl: require("@/assets/sideIcons/amendLease.svg"),
				},
				{
					label: 'Reservation Agreement',
					value: 'reservation',
					iconUrl: require("@/assets/sideIcons/reservation.svg"),
				},
				{
					label: "Supporting Documents",
					value: "other",
					iconUrl: require("@/assets/sideIcons/other.svg"),
				},
				{
					label: "Archived Documents",
					value: "archived",
					iconUrl: require("@/assets/sideIcons/archived.svg"),
				},
			];
			if (!this.$store.getters.isReservationActive) {
				items.splice(5, 1)
			}

			return items
		},
	},
	methods: {
		navOpened(e) {
			if (e.type === "parent") {
				this.$store.commit(
					"SET_FILTER",
					this.navItems.find((x) => x.value === e.value)
				);
				if (!this.$route.meta.listView && this.$route.path !== "/") this.$router.push("/");
			}
		},
	},
};
</script>
